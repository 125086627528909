class AbstractHomePage{
	constructor(){
		this.$wrapper = $('body');
		this.$header = this.$wrapper.find('header');
		this.$section = this.$wrapper.find('section');        
        this.$sliderWrapper = this.$wrapper.find('.slider');
        this.$form1 = this.$wrapper.find('#form-validate');
        this.$form2 = this.$wrapper.find('#mail-form');
        this.$preloader = this.$wrapper.find('.preloader');


		this.start();

		//preloader init
		// this.preloader();
		
		//init header state
		this.changeHeaderState();

		//form validation init
		this.formValidation();

		//init popup functionality
		var popup = new Popup();

		//init slider
		this.slider();
	}

	start(){
		console.log('privet, home zagrugen');
	}


	// header state
	changeHeaderState(){
		$(window).scroll((e) => {
			var $this = $(e.currentTarget);

			this.$section.each((i, elem)=>{
				if($this.scrollTop()>$(elem).offset().top-40){
					if($(elem).attr('data-color')== 1){
						this.$header.removeClass('other-bg');
					}else{
						this.$header.addClass('other-bg');
					}
				}
			});
		});

	}


	//slider functionality
	slider(){
		var slider = this.$sliderWrapper

		$(window).load( (e)=> {
			var $this = $(e.currentTarget);
			slider.on('init',()=>{
				this.$sliderWrapper.removeClass('inactive');
				$(window).scroll((e) => {
					var $this = $(e.currentTarget);

					if($this.scrollTop() >= this.$sliderWrapper.offset().top+this.$sliderWrapper.height()){
						if(!this.$sliderWrapper.hasClass('stop')){
							this.$sliderWrapper.addClass('stop');
							if(this.$sliderWrapper.hasClass('stop')){
								console.log('stop');
								slider.slick('slickPause');
							}
						}

					}else{
						return
					}

				});
			}).slick({
				centerMode: true,
				slidesToShow: 3,
				centerPadding: '0px',
				arrows:false,
				autoplay:true,
				autoplaySpeed:3000,
				infinite:true,
				speed:700,
				dots:true,
				pauseOnHover:false,
				appendDots:$('.slider-pagination')
			});
		});		
	}

	formValidation(){
		$('.mail-form .btn-hook').on('click', (e)=>{
			e.preventDefault();
			if(this.$form2.valid()){            
				this.$form2.ajaxSubmit({
					success: function(){
						$('.message2').fadeIn();
					}
				});

			} else{
				$('.message2').fadeOut();
			}  
		});

		$('.contact-form .btn').on('click', (e)=>{
			e.preventDefault();
			if(this.$form1 .valid()){            
				this.$form1 .ajaxSubmit({
					success: function(){
						$('.message').fadeIn();
					}
				});

			} else{
				$('.message').fadeOut();
			}  
		});

		this.$form2.validate({
			rules: {
				email2: {
					email: true,
					required: true,
				},
			},
			messages: {
				email2: {
					required: "Please enter a value",
				},
			}
		});

		this.$form1 .validate({
			// onkeyup: true,
			rules: {
				email: {
					email: true,
					required: true,
				},
				name: {
					textonly: true,
					required: true,
				},
				text:{
					textonly: true,
					required: true,
				}
			},
			messages: {
				email: {
					required: "This field is required",
				},
				name: {
					required: "This field is required",
				},
				text: {
					required: "This field is required"
				}
			},
			
		});

		jQuery.validator.addMethod(
			"textonly",
			function(value, element)
			{
				var valid = /[^-\.a-zA-Z\s\u00C0-\u00D6\u00D8-\u00F6\u00F8-\u02AE]/.test(value);
				return this.optional(element) || !valid;
			},
			jQuery.validator.format("")
			);

		jQuery.validator.addMethod(
			"email",
			function(value, element)
			{
				var valid = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/i.test(value);
				return this.optional(element) || valid;
			},
			jQuery.validator.format("An email address must contain a single @")
			);
	}
}