class Popup{
	constructor(){
		this.$wrapper = $('body');
		this.$popupWrapper = this.$wrapper.find('.popup-wrapper');
		this.$popupBody = this.$popupWrapper.find('.popup');
		this.$popupBtn = this.$wrapper.find('.popup-open');
		this.$popupBtn2 = this.$wrapper.find('.popup-open2');
		this.$popupCloseBtn = this.$popupWrapper.find('.close-popup');


		this.openPopup();
		this.closePopup();
	}

	
	openPopup(){
		this.$popupBtn.on('click', (e)=>{
			e.preventDefault();
			this.$popupWrapper.fadeIn();
		});
		this.$popupBtn2.on('click', (e)=>{
			e.preventDefault();
			this.$popupWrapper.fadeIn();
		});
	}

	// close popup
	closePopup(){
		this.$wrapper.on('click', (e) =>{
			if(!$(e.target).closest(this.$popupBody).length && !$(e.target).closest(this.$popupBtn).length&& !$(e.target).closest(this.$popupBtn2).length){
				this.$popupWrapper.fadeOut();
			}
		});

		this.$popupCloseBtn.on('click', (e) =>{
			e.preventDefault();
			this.$popupWrapper.fadeOut();
		});
	}
}